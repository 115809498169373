<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
  ref,
} from "vue";
import InputText from "primevue/inputText";
import Dropdown from "primevue/dropdown";
import { dateFormate } from "../tool/formate";
import OverlayPanel from "primevue/overlaypanel";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import EmployerView from "./Employer.vue";
import Chip from "primevue/chip";
import Calendar from "primevue/calendar";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import Toast from "primevue/toast";
import User from "../api/salary/auth/user";
import AdminAppointment from "../api/salary/admin/appointment";
import { useI18n } from "../i18nPlugin";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { printHTML } from "../tool/print";
import AdminEmployerShift from "../api/salary/admin/employer_shift_relation";

import PrintAppointmentList from "./PrintAppointmentList.vue";
import PrintNewAppointmentList from "./PrintNewAppointmentList.vue";

import "@fullcalendar/core";

const PrintAppointment = ref(null);
const fullCalendar = ref();
const op = ref();
let userApi = new User();
let admin_appointmentAPI = new AdminAppointment();
let admin_employer_shiftAPI = new AdminEmployerShift();

const i18n = useI18n();
const toast = useToast();
const store = useStore();

const data = reactive({
  selectStatusList: [
    {
      id: 1,
      name: i18n.$t("Appointment").AppointmentTable.StatusShow
        .ReviewingAppointmentStatus,
    },
    {
      id: 2,
      name: i18n.$t("Appointment").AppointmentTable.StatusShow
        .ReviewedAppointmentStatus,
    },
    // {
    //   id: 3,
    //   name: i18n.$t("Appointment").AppointmentTable.StatusShow
    //     .CancelAppointmentStatus,
    // },
  ],
  date_confirm: null,
  search_date: new Date(),
  AddShiftSearch: "",
  selectShiftList: [],
  fastDialog: false,
  shift_contents: [],
  shift_content: null,
  shift_content_title: "",
  options: {
    locale: "zh-tw",
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    // initialDate:
    //   new Date().getFullYear() +
    //   "-" +
    //   (new Date().getMonth() + 1) +
    //   "-" +
    //   new Date().getDate(),
    dateClick: handleDateClick,
    customButtons: {
      prev: {
        // this overrides the prev button
        text: "PREV",
        click: () => {
          let calendarApi = fullCalendar.value.getApi();
          calendarApi.prev();
          data.search_date =
            fullCalendar.value.calendar.currentData.dateProfile.currentDate;
        },
      },
      next: {
        // this overrides the next button
        text: "PREV",
        click: () => {
          let calendarApi = fullCalendar.value.getApi();
          calendarApi.next();
          data.search_date =
            fullCalendar.value.calendar.currentData.dateProfile.currentDate;
        },
      },
    },
    headerToolbar: {
      left: "prev,next",
      center: "title",
      right: "dayGridMonth timeGridWeek timeGridDay",
      //   timeGridDay
    },
    buttonText: {
      month: "月",
      week: "週",
      day: "日",
    },
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    events: null,
  },
  appointmentFilters: {
    customer_name: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});

function handleDateClick(arg) {
  data.shift_content_title = arg.dateStr;
  data.shift_contents = [];
  if (data.options.events != null)
    for (let i = 0; i < data.options.events.length; i++) {
      if (
        dateFormate(data.options.events[i].start, "YYYY-MM-DD") ==
        dateFormate(arg.dateStr, "YYYY-MM-DD")
      ) {
        data.shift_contents.push(data.options.events[i]);
      }
    }
  data.AddShiftSearch = "";
  data.chooseShiftDialog = true;
  op.value.hide();
}

function getShift() {
  let tmp_ids = [];
  for (let i = 0; i < store.state.bouns_employer.length; i++) {
    tmp_ids.push(store.state.bouns_employer[i].id);
  }
  let start = new Date(
    data.search_date.getFullYear(),
    data.search_date.getMonth(),
    1,
    0,
    0
  );
  let end = new Date(
    data.search_date.getFullYear(),
    data.search_date.getMonth() + 1,
    0,
    23,
    59,
    59
  );

  admin_appointmentAPI
    .getAppointmentList(
      data.appointmentFilters.customer_name.value,
      start.toISOString(),
      end.toISOString(),
      data.appointmentFilters.status.value,
      1,
      10000
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        let tmp_data = res.data.data.datas;
        let tmp = [];
        if (tmp_data != null)
          for (let i = 0; i < tmp_data.length; i++) {
            if (tmp_data[i].appointment_content != null)
              for (let j = 0; j < tmp_data[i].appointment_content.length; j++) {
                let tmp_start = new Date(
                  tmp_data[i].appointment_content[j].start_time
                );
                let tmp_end = new Date(
                  tmp_data[i].appointment_content[j].end_time
                );

                tmp.push({
                  //   id: tmp_data[i].employer_shift_relations[j].shift_id,
                  shift_id: tmp_data[i].id,
                  title:
                    tmp_data[i].appointment_content[j].employer.id == 0
                      ? tmp_data[i].appointment_content[j].service.name +
                        "(不指定)"
                      : tmp_data[i].appointment_content[j].service.name +
                        "(" +
                        tmp_data[i].appointment_content[j].employer.name +
                        "|號碼：" +
                        tmp_data[i].appointment_content[j].employer.id +
                        ")",
                  //   tmp_data[i].appointment_content[j].service.name +
                  //   "|" +
                  //   tmp_data[i].appointment_content[j].employer.name +
                  //   "[號碼：" +
                  //   tmp_data[i].appointment_content[j].employer.id +
                  //   "]",
                  start: tmp_start,
                  end: tmp_end,
                  service: tmp_data[i].appointment_content[j].service.name,
                  employer_name:
                    tmp_data[i].appointment_content[j].employer.id == 0
                      ? "不指定"
                      : tmp_data[i].appointment_content[j].employer.name +
                        "(號碼：" +
                        tmp_data[i].appointment_content[j].employer.id +
                        ")",
                  customer_name: tmp_data[i].customer_name,
                  employer_id: tmp_data[i].appointment_content[j].employer_id,
                  status: tmp_data[i].status,
                  color: "#33F4FF",
                });
              }
          }
        data.options.events = tmp;
        // data.events = tmp;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function toggle(event, arg) {
  data.chooseShiftDialog = false;
  data.shift_content = arg;
  op.value.toggle(event);
}

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      store.commit("set_user", res.data.data);
    } else {
      store.commit("set_user", null);
    }
  });
}

const mounted = onMounted(async () => {
  CheckUser();
  getShift();
});

watch(
  () => data.appointmentFilters.customer_name.value,
  () => {
    getShift();
  }
);

watch(
  () => data.StatusSearch,
  () => {
    if (data.StatusSearch != null && data.StatusSearch != "") {
      data.appointmentFilters.status.value = data.StatusSearch.id;
    } else {
      data.appointmentFilters.status.value = 0;
    }
    getShift();
  }
);

watch(
  () => data.search_date,
  () => {
    getShift();
  }
);
async function PrintShift(print) {
  let tmp = [];
  let tmp_tmp = [];
  let check = [];
  let tmp_shift_data = [];
  for (let i = 0; i < data.shift_contents.length; i++) {
    if (data.shift_contents[i].status == 2) tmp.push(data.shift_contents[i]);
  }

  if (tmp.length > 0) {
    let start = new Date(
      tmp[0].start.getFullYear(),
      tmp[0].start.getMonth(),
      tmp[0].start.getDate(),
      0,
      0
    );
    let end = new Date(
      tmp[0].start.getFullYear(),
      tmp[0].start.getMonth(),
      tmp[0].start.getDate(),
      23,
      59,
      59
    );
    admin_employer_shiftAPI
      .getEmployerShiftList([], start.toISOString(), end.toISOString())
      .then((res) => {
        if (res.data.error_code == 0) {
          tmp_shift_data = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      })
      .then(async () => {
        tmp.sort((a, b) => {
          // 先比較 name 屬性，如果不同就直接回傳比較結果
          // if (a.employer_name !== b.employer_name) {
          //   return a.employer_name.localeCompare(b.employer_name);
          // }
          if (a.employer_id !== b.employer_id) {
            return a.employer_id - b.employer_id;
          }
          // 如果 name 相同就比較 time 屬性
          return a.start.toISOString().localeCompare(b.start.toISOString());
        });
        for (let i = 0; i < tmp.length; i++) {
          if (!check.includes(tmp[i].employer_name)) {
            check.push(tmp[i].employer_name);
            let shift_name = "";
            for (let j = 0; j < tmp_shift_data.length; j++) {
              if (tmp_shift_data[j].employer_shift_relations.length > 0) {
                if (tmp_shift_data[j].employer_id == tmp[i].employer_id)
                  shift_name =
                    tmp_shift_data[j].employer_shift_relations[0].shift.name;
              }
            }
            tmp_tmp.push({
              employer_name: tmp[i].employer_name,
              shift: shift_name,
              service: [
                {
                  name: String(tmp[i].service)
                    .replaceAll("整體平衡", "A")
                    .replaceAll("按摩指壓", "B")
                    .replaceAll("腿部循環", "L")
                    .replaceAll("腳底按摩", "F")
                    .replaceAll("美顏平衡", "H"),
                  customer: tmp[i].customer_name,
                  time_start:
                    ((tmp[i].start.getHours() * 60 +
                      tmp[i].start.getMinutes() -
                      10 * 60) /
                      30) *
                    50,
                  time_width:
                    ((tmp[i].end.getHours() * 60 +
                      tmp[i].end.getMinutes() -
                      (tmp[i].start.getHours() * 60 +
                        tmp[i].start.getMinutes())) /
                      30) *
                    50,
                },
              ],
            });
          } else {
            tmp_tmp[check.length - 1].service.push({
              name: String(tmp[i].service)
                .replaceAll("整體平衡", "A")
                .replaceAll("按摩指壓", "B")
                .replaceAll("腿部循環", "L")
                .replaceAll("腳底按摩", "F")
                .replaceAll("美顏平衡", "H"),
              customer: tmp[i].customer_name,
              time_start:
                ((tmp[i].start.getHours() * 60 +
                  tmp[i].start.getMinutes() -
                  10 * 60) /
                  30) *
                50,
              time_width:
                ((tmp[i].end.getHours() * 60 +
                  tmp[i].end.getMinutes() -
                  (tmp[i].start.getHours() * 60 + tmp[i].start.getMinutes())) /
                  30) *
                50,
            });
          }
        }
        await store.commit("set_appointment_list", tmp_tmp);
        printHTML(print);
        // data.side_show = true;
      });
  }
}
async function PrintNewShift(print) {
  let tmp = [];
  let tmp_tmp = [];
  let check = [];
  let tmp_shift_data = [];
  for (let i = 0; i < data.shift_contents.length; i++) {
    if (data.shift_contents[i].status == 2) tmp.push(data.shift_contents[i]);
  }

  if (tmp.length > 0) {
    let start = new Date(
      tmp[0].start.getFullYear(),
      tmp[0].start.getMonth(),
      tmp[0].start.getDate(),
      0,
      0
    );
    let end = new Date(
      tmp[0].start.getFullYear(),
      tmp[0].start.getMonth(),
      tmp[0].start.getDate(),
      23,
      59,
      59
    );
    admin_employer_shiftAPI
      .getEmployerShiftList([], start.toISOString(), end.toISOString())
      .then((res) => {
        if (res.data.error_code == 0) {
          tmp_shift_data = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      })
      .then(async () => {
        tmp.sort((a, b) => {
          // 先比較 name 屬性，如果不同就直接回傳比較結果
          // if (a.employer_name !== b.employer_name) {
          //   return a.employer_name.localeCompare(b.employer_name);
          // }
          if (a.employer_id !== b.employer_id) {
            return a.employer_id - b.employer_id;
          }
          // 如果 name 相同就比較 time 屬性
          return a.start.toISOString().localeCompare(b.start.toISOString());
        });
        for (let i = 0; i < tmp.length; i++) {
          if (!check.includes(tmp[i].employer_name)) {
            check.push(tmp[i].employer_name);
            let shift_name = "";
            for (let j = 0; j < tmp_shift_data.length; j++) {
              if (tmp_shift_data[j].employer_shift_relations.length > 0) {
                if (tmp_shift_data[j].employer_id == tmp[i].employer_id)
                  shift_name =
                    tmp_shift_data[j].employer_shift_relations[0].shift.name;
              }
            }
            tmp_tmp.push({
              employer_name: tmp[i].employer_name,
              shift: shift_name,
              service: [
                {
                  name: String(tmp[i].service)
                    .replaceAll("整體平衡", "A")
                    .replaceAll("按摩指壓", "B")
                    .replaceAll("腿部循環", "L")
                    .replaceAll("腳底按摩", "F")
                    .replaceAll("美顏平衡", "H"),
                  customer: tmp[i].customer_name,
                  time_start:
                    ((tmp[i].start.getHours() * 60 +
                      tmp[i].start.getMinutes() -
                      10 * 60) /
                      30) *
                    50,
                  time_width:
                    ((tmp[i].end.getHours() * 60 +
                      tmp[i].end.getMinutes() -
                      (tmp[i].start.getHours() * 60 +
                        tmp[i].start.getMinutes())) /
                      30) *
                    50,
                },
              ],
            });
          } else {
            tmp_tmp[check.length - 1].service.push({
              name: String(tmp[i].service)
                .replaceAll("整體平衡", "A")
                .replaceAll("按摩指壓", "B")
                .replaceAll("腿部循環", "L")
                .replaceAll("腳底按摩", "F")
                .replaceAll("美顏平衡", "H"),
              customer: tmp[i].customer_name,
              time_start:
                ((tmp[i].start.getHours() * 60 +
                  tmp[i].start.getMinutes() -
                  10 * 60) /
                  30) *
                50,
              time_width:
                ((tmp[i].end.getHours() * 60 +
                  tmp[i].end.getMinutes() -
                  (tmp[i].start.getHours() * 60 + tmp[i].start.getMinutes())) /
                  30) *
                50,
            });
          }
        }
        await store.commit("set_appointment_list", tmp_tmp);
        // printHTML(print);
        data.side_show = true;
      });
  }
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <div style="margin-top: 90px" class="p-text-left">
      <div class="p-col-12">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <InputText
            style="width: 150px"
            v-model="
              data.appointmentFilters[
                i18n.$t('Appointment').AppointmentTable.CustomerName.Field
              ].value
            "
            :placeholder="
              i18n.$t('Appointment').AppointmentTable.CustomerName.Show
            "
          />
        </span>
        <span class="p-input-icon-left p-ml-1">
          <i class="pi pi-search" />
          <Dropdown
            class="p-ml-1"
            v-model="data.StatusSearch"
            :options="data.selectStatusList"
            optionLabel="name"
            :filter="true"
            :placeholder="i18n.$t('Appointment').AppointmentTable.Status.Show"
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>
        </span>
      </div>
      <div class="p-col-12">
        <FullCalendar
          ref="fullCalendar"
          :events="data.events"
          :options="data.options"
          aria-haspopup="true"
          aria-controls="overlay_panel"
        >
          <template v-slot:eventContent="arg">
            <b
              :style="
                arg.event.extendedProps.status == 1
                  ? 'Color:orange;overflow:auto'
                  : arg.event.extendedProps.status == 2
                  ? 'Color:green;overflow:auto'
                  : arg.event.extendedProps.status == 3
                  ? 'Color:red;overflow:auto'
                  : 'Color:red;overflow:auto'
              "
              @click="toggle($event, arg.event)"
              aria-haspopup="true"
              aria-controls="overlay_panel"
            >
              {{ dateFormate(arg.event.start, "hh:mm") }}-
              {{ dateFormate(arg.event.end, "hh:mm") }}
              {{ arg.event.title }}</b
            >
          </template>
        </FullCalendar>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="data.chooseShiftDialog"
    header="當日預約"
    style="width: 600px; min-width: 375px"
  >
    日期：{{ dateFormate(data.shift_content_title, "YYYY-MM-DD") }}<br />
    <div>
      <div class="p-grid">
        <div class="p-col-3">服務項目</div>
        <div class="p-col-3">時間</div>
        <div class="p-col-3">客人</div>
        <div class="p-col-3">美甲師</div>
      </div>
      <div style="overflow: scroll; height: 200px">
        <div
          :key="index"
          v-for="(shift, index) in data.shift_contents"
          class="p-grid"
        >
          <div class="p-col-3">
            {{ shift.service }}
          </div>
          <div class="p-col-3">
            {{ dateFormate(shift.start, "hh:mm") }}-{{
              dateFormate(shift.end, "hh:mm")
            }}
          </div>
          <div class="p-col-3">
            {{ shift.customer_name }}
          </div>
          <div class="p-col-3">
            {{ shift.employer_name }}
          </div>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-12 p-text-right">
          <Button
            icon="pi pi-print"
            label="列印"
            class="p-button-danger p-button-sm p-mr-1"
            @click="PrintShift(PrintAppointment)"
          />
          <Button
            icon="pi pi-print"
            label="顯示"
            class="p-button-info p-button-sm"
            @click="PrintNewShift(PrintAppointment)"
          />
        </div>
      </div>
    </div>
  </Dialog>
  <OverlayPanel
    ref="op"
    appendTo="body"
    :showCloseIcon="true"
    id="overlay_panel"
    style="width: 450px"
    :breakpoints="{ '960px': '75vw' }"
  >
    <h3>{{ data.shift_content.title }}</h3>
    日期：{{ dateFormate(data.shift_content.start, "YYYY-MM-DD") }}<br />
    服務項目：{{ data.shift_content.extendedProps.service }}<br />
    客人：{{ data.shift_content.extendedProps.customer_name }}<br />
    美甲師：{{ data.shift_content.extendedProps.employer_name }}<br />
    開始時間：{{ dateFormate(data.shift_content.start, "hh:mm") }}<br />
    結束時間：{{ dateFormate(data.shift_content.end, "hh:mm") }}<br />
  </OverlayPanel>
  <Sidebar v-model:visible="data.side_show" position="full">
    <div class="p-mt-3">
      <PrintNewAppointmentList />
    </div>
  </Sidebar>

  <div class="p-d-none">
    <div ref="PrintAppointment">
      <PrintNewAppointmentList />
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
