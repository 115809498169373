<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  onUpdated,
  toRefs,
  computed,
  watch,
} from "vue";
import { dateFormate } from "../tool/formate";
import { useStore } from "vuex";
const store = useStore();
// const i18n = useI18n();
// const toast = useToast();
const data = reactive({});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {});
</script>

<template>
  <div
    style="
      box-sizing: border-box;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -0.5rem;
      margin-left: -0.5rem;
      margin-top: -0.5rem;
    "
  >
    <div
      style="
        box-sizing: border-box;
        border-bottom: 1px solid black;
        width: 1250px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      "
    >
      <div
        style="
          box-sizing: border-box;
          width: 150px;
          text-align: center;
          border: 1px solid black;
        "
      >
        &nbsp;美甲師&nbsp;
      </div>
      <div
        style="
          box-sizing: border-box;
          width: 100px;
          text-align: center;
          border: 1px solid black;
        "
      >
        &nbsp;10 &nbsp;
      </div>
      <div
        style="
          box-sizing: border-box;
          width: 100px;
          text-align: center;
          border: 1px solid black;
        "
      >
        &nbsp;11 &nbsp;
      </div>
      <div
        style="
          box-sizing: border-box;
          width: 100px;
          text-align: center;
          border: 1px solid black;
        "
      >
        &nbsp;12&nbsp;
      </div>
      <div
        style="
          box-sizing: border-box;
          width: 100px;
          text-align: center;
          border: 1px solid black;
        "
      >
        &nbsp;13 &nbsp;
      </div>
      <div
        style="
          box-sizing: border-box;
          width: 100px;
          text-align: center;
          border: 1px solid black;
        "
      >
        &nbsp;14 &nbsp;
      </div>
      <div
        style="
          box-sizing: border-box;
          width: 100px;
          text-align: center;
          border: 1px solid black;
        "
      >
        &nbsp;15 &nbsp;
      </div>
      <div
        style="
          box-sizing: border-box;
          width: 100px;
          text-align: center;
          border: 1px solid black;
        "
      >
        &nbsp;16 &nbsp;
      </div>
      <div
        style="
          box-sizing: border-box;
          width: 100px;
          text-align: center;
          border: 1px solid black;
        "
      >
        &nbsp;17 &nbsp;
      </div>
      <div
        style="
          box-sizing: border-box;
          width: 100px;
          text-align: center;
          border: 1px solid black;
        "
      >
        &nbsp;18 &nbsp;
      </div>
      <div
        style="
          box-sizing: border-box;
          width: 100px;
          text-align: center;
          border: 1px solid black;
        "
      >
        &nbsp;19 &nbsp;
      </div>
      <div
        style="
          box-sizing: border-box;
          width: 100px;
          text-align: center;
          border: 1px solid black;
        "
      >
        &nbsp;20 &nbsp;
      </div>
    </div>
    <div
      style="
        box-sizing: border-box;
        border-bottom: 1px solid black;
        width: 1250px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      "
      :key="index"
      v-for="(shift, index) in store.state.appointment_list"
    >
      <div
        :style="'box-sizing: border-box;width: 150px; text-align: center;height:70px'"
      >
        &nbsp;{{ shift.employer_name }}&nbsp;<br />
        &nbsp;{{ `(班別：${shift.shift})` }}&nbsp;
      </div>
      <div
        :style="`
        box-sizing: border-box;
          width: 1100px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          font-size: 10px;
          margin-top:-70px;
          margin-left: ${(index_service = 0 ? 0 : 150)}px;`"
        :key="index_service"
        v-for="(item, index_service) in shift.service"
      >
        <div
          :style="
            'width: ' +
            item.time_start +
            'px;height:70px;box-sizing: border-box;'
          "
        >
          &nbsp; &nbsp;
        </div>
        <div
          :style="
            'width: ' +
            item.time_width +
            'px; text-align: center; border: 1px solid black;height:70px;box-sizing: border-box;'
          "
        >
          &nbsp;{{ item.name }}&nbsp;<br />
          &nbsp;{{ item.customer }}&nbsp;
        </div>
        <div
          :style="
            'width: ' +
            (1100 - item.time_start - item.time_width) +
            'px;height:70px;box-sizing: border-box;'
          "
        >
          &nbsp; &nbsp;
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
